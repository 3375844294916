import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 월 1만원 대에 데이터ㆍ통화ㆍ문자 무제한 이용 가능, 벨킨 정품 고속 충전기 혜택까지",
  "| “고객 요청에 부응… 아이폰 유저라면, 전용 요금제로 혜택 챙기세요!”",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/26/FambZJal.png" style={{ maxWidth: "400px", margin: "auto" }} alt="Figure1" />
      </div>
      <div className="desc">
        △핀다이렉트샵이 19일(화)부터 아이폰 유저를 위한 <br />
        아이폰 전용 요금제를 선착순 1만 명에 한정하여 판매한다.
      </div>
    </div>
    <p>
      <br />
      <br />
      최근 IT 전문 인기 유튜버 잇섭이 주목할 만큼 ‘자급제와 알뜰폰 요금제의 꿀조합’이 인기인 가운데, 고객 성원에 힘입은 아이폰
      ‘전용’ 요금제가 발빠르게 출시됐다.
      <br />
      <br />
      스테이지파이브(대표 서상원)의 통신 판매 플랫폼 ‘핀다이렉트샵’은 20일(수)부터 아이폰 유저라면 누구나 신청 가능한 ‘아이폰 전용
      요금제’를 선보인다. 아이폰13 시리즈 구매자 뿐만 아니라 전체 아이폰 유저들이 타깃이다.
      <br />
      <br />
      약 50% 할인된 프로모션 가격으로 월 1만원 대에 데이터(월 11GB+일 2GB 추가+3Mbps)와 문자 및 통화(영상 및 부가통화 200분)를
      무제한으로 누릴 수 있는 이 요금제는 선착순 1만 명 한정 판매한다. 가입 시 벨킨 고속 충전기를 100% 지급하며, 경품 추첨을 통해
      카카오프렌즈 스마트 체중계를 증정하는 이벤트도 동시에 진행한다.
      <br />
      <br />
      다소 특별한 이번 요금제의 탄생 배경에는 아이폰 유저들의 성원이 있었다. ‘아이폰 특화 요금제가 늘어나면 좋겠다’는 고객 의견을
      적극 반영했기 때문이다. 해당 의견은 이달 초부터 핀다이렉트샵 카카오톡 상담에 유입된 전체 상담의 5.7% 수준으로 꾸준히 이어질
      정도였다.
      <br />
      <br />
      아이폰 유저를 위해 탄생한 이번 혜택은 아이폰 시리즈를 소지한 고객이라면 구입처나 컬러, 용량에 상관없이 모두 신청 가능하다.
      자세한 내용은 핀다이렉트샵(www.pindirectshop.com)에서 확인 가능하다.
      <br />
      <br />
      <br />
      | 통신사 기기결합 및 자급제까지 한번에…핀다이렉트샵
      <br />
      <br />
      <div className="descImage">
        <div className="img">
          <img src="/images/press/26/HySLxPVJ.jpg" style={{ maxWidth: "200px", margin: "auto" }} alt="Figure2" />
        </div>
      </div>
      <br />
      통신업계에서 통신사 기기결합으로 아이폰13 사전예약 판매를 진행하는 동시에 자급제를 위한 유심 요금제를 함께 취급하는 플랫폼은
      찾기 어렵다. 그러나 스테이지파이브 통신 판매 플랫폼 핀다이렉트샵(www.pindirectshop.com)은 스마트폰 디바이스와 다양한 요금제
      등 통신 관련 상품을 한 데 모아 소비자 선택권을 확대하는 데 초점을 두고 있다.
      <br />
      <br />
      핀다이렉트샵에서는 최신상 스마트폰과 합리적인 요금이 매칭된 기기결합 요금제, 자급제폰과 찰떡궁합인 알뜰폰(MVNO) 유심 요금제
      등 다양한 종류의 통신 요금제도 간편하게 비교할 수 있다. 신한 플러스, 멜론, SPC 요금제 등 이종산업간 결합한 핀다이렉트샵 만의
      제휴요금제도 준비돼 있다. 뿐만 아니라 최신 5G 스마트폰은 물론, 2단계 품질 검수를 거치는 ‘핀다이렉트케어’ 중고폰까지 한 곳에
      모여 있어 고객 선택권도 한층 강화됐다.
      <br />
      <br />
      뿐만 아니라 핀다이렉트샵은 세련되고 편리한 UX 설계로 MZ세대에 좋은 반응을 얻고 있는 비대면 통신 가입 플랫폼이다. PC는 물론
      카카오톡 채널을 비롯하여 웹과 모바일을 통한 접근이 가능하며, 카카오페이를 통한 본인인증 등 가입 절차, 배송 및 상담 등 구매
      이후 절차 역시 카카오톡으로 간편하게 이용할 수 있다. 이곳저곳 흩어진 포인트를 한 번에 모아 선 할인 받을 수 있는 것도
      강점이다.
      <br />
      <br />
      <br />
    </p>
  </div>
)

const press15 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴CR실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 10월 20일 즉시 배포 가능"
      pageInfo="*총 2매 / 첨부사진 2매"
      title="핀다이렉트샵, 선착순 1만 명 한정 아이폰 전용 요금제 런칭"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press15
